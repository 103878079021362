@media (max-width: 1300px){
    .s3 .owl-nav .owl-prev{
        left: 0;
    }


}
@media (max-width: 1200px){
    .s3 .owl-nav .owl-prev{
        left: 0;
    }
    
    .s3{

        .item{
           .lb{
              margin-left: 90px;
           } 
        }
    }
   .s8 .lb .text {
        margin-top: 45px;
        margin-left: 18px;
    }
    .s9 .text {
        margin-top: 57px;
    }

    footer .menu_bot ul:nth-child(2) {
        margin-left: 20px;
    }
    footer .menu_bot{
        font-size: 16px;
    }
    footer .adr p br{
        display: none;
    }
    header .menu ul{
        margin-left: 0;
        justify-content: flex-start;
    }
    header .logo{
        max-width: 150px;
        margin-top: 15px;
        img{
            width: 100%;
        }
    }
    .s2 .search input{
        width: 270px;
    }
    .s9 img{
        width: 100%;
    }

}
@media (min-width: 576px){
    .tabs{
        width: 540px!important;
    }
}
@media (min-width: 768px){
    .tabs{
        width: 720px!important;
    }
}
@media (min-width: 992px){
    .tabs{
        width: 960px!important;
    }
}


@media (min-width: 1200px){
    .tabs{
        width:1175px!important;
    }
}
@media (max-width: 992px){

    .menu__icon{
        display: inline-block;
      }
    .s2 .tabs__content.active ul li{
        font-size: 12px;
        line-height: 17px;
    }
    .s2 .tabs__content.active{
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 25px;
    }

    .s2 .tabs__caption li{
        font-size: 12px;
        padding: 10px 0;
        padding-left: 15px;
    }
    .s2 .tabs__caption {
        max-width: 170px;
    }
    .menu__links {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    left: 0;
    margin-top: 52px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    overflow: auto;
    }

    .menu__links-item {
    display: block;
    padding: 10px 0;
    text-align: center;
    color: #ffffff;
    }


    .s3 .owl-nav .owl-prev{
        left: 0;
    }
    header{

        .menu{
            display: none;
        }
    }
    .s2 .search input {
        width: 117px;
    }
    .s2 .search{
        margin-left: -80px;
    }
    .s2 .sub_menu ul li a {
        color: #fff;
        font-size: 13px;
    }
    .sub_menu{
        margin-left: -60px;
    }
    .s3 .rb img{
        width: 100%;
    }
    .s3 h1 {
        line-height: 30px;
        font-family: 'Gotham Pro Light';
        font-size: 27px;
        color: #fff;
    }
    .s3 p {
        margin-top: 19px;
        line-height: 17px;
        font-family: 'Uni Neue';
        font-weight: 400;
        font-size: 15px;
        color: #fff;
    }
    .s4 .item{
        padding: 0 8px!important;
    }
    .s4 h3{
        font-size: 13px;
    }
    .s5 .items.d-flex{
        display: block!important;
    }
    .s5 .items .item{
        margin: 10px auto;
    }
    .s5 .items .item.size-x2{
        margin-bottom: 80px;
    }
    h2.title{
        text-align: center;
    }
    a.show_more{
        float: none;
        margin: 20px auto; 
        display: block;
        width: max-content;
    }
    .s5 .items .item.size-x2 .buy{
        margin-bottom: -65px;
    }
    .s8{
        background-size: cover;
    }
    .s8 .d-flex{
        display: block!important;
        text-align: center;
    }
    .s8 .lb{
        text-align: center;
        margin: 0 auto;
    }
    .s8 .lb .ava{
        max-width: 100px;
        margin: 20px auto 0;
    }
    .s8 .lb .ava .name{
        margin-top: 10px;
    }
    .s8 .lb .text{
        margin-top: 10px;
    }
    .s8 .rb{
        margin: 15px auto 0;
    }
    footer{

        .logo{
            img{
                width: 100%;
            }
        }
    }
    footer .menu_bot{
        display: block!important;
    }
    footer .menu_bot ul:nth-child(2){
        margin-left: 0;
    }footer .contact{
        padding-left: 0;
    }
    footer .phone a{
        font-size: 15px;
    }
    footer .logo img {
        width: 100%;
        max-width: 150px;
    }
    .s3{
        background-size: cover;
    }

    .s10 .catalog_items h3{
        font-size: 20px;
    }
    .s10 .catalog_items{
        padding: 20px 0;
    }
    .s10 .accordion .title_block{
        padding: 10px 10px 10px 12px;
    }
    .s10 .accordion .title_block:before{
        right: 20px;
    }
    .s10 .accordion .info {
        padding: 18px 13px 30px 25px;
    }
.s10 .accordion .info ul li {
    font-family: "Uni Neue";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px;
}
.s10 .item{
    margin-left: 5px;
}
.s10 .items{
    padding-left: 0;
}
.s12 .align-center .img img{
    width: 100%;
    max-width: 300px;
}
.s12 .dop_info a{
    margin-top: 30px;

    br{
        display: none;
    }
}

.s12 .description {
    margin: 30px auto 0;
}


}
@media (max-width: 768px){
.s10 .items {
    justify-content: space-between;
}

    .s2 .tabs__content.active{
        display: block;
    }
    .menu__icon{
        left: 20px;
        z-index: 5;
    }
    header{
        text-align:center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    header .phone a{
        width: max-content;
        margin: 0 auto;
    }
    header .logo{
        margin: 0 auto;
    }
    .s2 .catalog_link a{
        width: max-content;
    }
    .s2 .catalog_link{
        width: max-content;
        margin-left: 0;
    }
    .sub_menu{
        margin-left: 0;
    }
    .s2 .search{
        margin-left: 0;
    }
    .s2 .justify-content-between{
            justify-content: flex-start!important;
    }
    .s2 .search input {
        width: 100%;
    }
    .s4{

        .d-flex{
            display: block!important;
        }
    }
    .s4 .item{
        border:none;
        text-align: center;
        margin: 0 auto!important;
    }
    .s4{
        padding: 20px 0;
    }
    .s5{
        padding-top: 20px;
    }
    .s5 .items{
        margin-top: 20px;
    }
    footer{
        padding-top: 20px;
    }
    .s9{
        padding: 20px 0;
    }
    .s8{
        padding: 20px 0;
    }

    .s3 .d-flex{
        display: block!important;
    }
    .s3 .item .lb{
        text-align: center;
        margin-left: 0;
    }
    .s3 a{
        margin: 20px auto 0;
    }
    .s3{
        background-position: center;
    }

}
@media (max-width: 580px){
    .s2 .vertical .tabs__caption{
        float: none;
    }
    .s2 .tabs__caption{
        width: 100%;
        max-width: 100%;
    }
    .s12 .teh_opisanie a{
        padding: 25px 5px;
    }

}
@media (max-width: 493px){
    .s10 .items {
        justify-content: center;
    }
}

@media (max-width: 480px){

    .s2 .sub_menu ul li a {
        color: #fff;
        font-size: 9px;
    }
    .s3 .owl-carousel{
        padding: 30px 0;
    }
    .s3 .owl-nav .owl-next{
        width: 30px;
    }
    .s3 .owl-nav .owl-prev{
        width: 30px;
    }
    .s5 .items .item.size-x2{
        display: block!important;
    }
    footer .copy{
        margin-top: 20px;
    }
}