@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

a, input[type=submit] {
  transition: .4s ease-out; }

body {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  font-weight: 400; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=text], textarea, button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline-style: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

@font-face {
  font-family: 'Gotham Pro Regular';
  src: url("fonts/GothamPro.eot");
  src: url("fonts/GothamPro.eot?#iefix") format("embedded-opentype"), url("fonts/GothamPro.woff") format("woff"), url("fonts/GothamPro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gotham Pro Light';
  src: url("fonts/ofont.ru_Gotham Pro.ttf");
  src: url("fonts/ofont.ru_Gotham Pro.ttf?#iefix") format("embedded-opentype"), url("fonts/ofont.ru_Gotham Pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Gotham Pro Black';
  src: url("fonts/GothamPro-Black.eot");
  src: url("fonts/GothamPro-Black.eot?#iefix") format("embedded-opentype"), url("fonts/GothamPro-Black.woff") format("woff"), url("fonts/GothamPro-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Uni Neue';
  src: local("Uni Neue Regular"), local("Uni-Neue-Regular"), url("fonts/UniNeueRegular/UniNeueRegular.woff2") format("woff2"), url("fonts/UniNeueRegular/UniNeueRegular.woff") format("woff"), url("fonts/UniNeueRegular/UniNeueRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Uni Neue';
  src: local("Uni Neue Bold"), local("Uni-Neue-Bold"), url("fonts/UniNeueBold/UniNeueBold.woff2") format("woff2"), url("fonts/UniNeueBold/UniNeueBold.woff") format("woff"), url("fonts/UniNeueBold/UniNeueBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Uni Neue';
  src: local("Uni Neue Regular Italic"), local("Uni-Neue-Regular-Italic"), url("fonts/UniNeueRegular-Italic/UniNeueRegular-Italic.woff2") format("woff2"), url("fonts/UniNeueRegular-Italic/UniNeueRegular-Italic.woff") format("woff"), url("fonts/UniNeueRegular-Italic/UniNeueRegular-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

body {
  background: #f5f5f5; }

a {
  text-decoration: none; }

@media (min-width: 1200px) {
  .container {
    max-width: 1175px; } }

h2.title {
  color: #00121e;
  font-size: 30px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: 'Gotham Pro Black'; }

header {
  background: #fff;
  padding-top: 33px;
  padding-bottom: 29px; }
  header .phone {
    margin-top: 1px; }
    header .phone a {
      padding-left: 33px;
      display: block;
      background-image: url(../img/telephone.png);
      background-repeat: no-repeat;
      background-position: left center;
      font-family: 'Gotham Pro Regular';
      font-size: 18px;
      line-height: 30px;
      color: #00121e; }
  header .menu ul {
    display: flex;
    justify-content: space-around;
    font-family: 'Uni Neue';
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 22px;
    margin-left: 10px; }
    header .menu ul li {
      border: 1px solid #fff;
      transition: .4s all;
      padding: 4px 7px; }
      header .menu ul li:hover {
        border: 1px solid #fdc218; }
    header .menu ul a {
      color: #00121e; }

.s2 {
  background: #00121e;
  padding-top: 14px;
  padding-bottom: 14px; }
  .s2 .tabs__content {
    display: none;
    /* по умолчанию прячем все блоки */ }
  .s2 .tabs__content.active {
    display: block;
    /* по умолчанию показываем нужный блок */ }
  .s2 .catalog_sub_menu {
    width: 100%; }
  .s2 .tabs {
    position: absolute;
    display: none;
    z-index: 2;
    left: 0px;
    width: 1175px;
    margin: 0 0 30px;
    background: rgba(0, 19, 30, 0.8);
    padding-bottom: 45px; }
  .s2 .tabs.open {
    display: block; }
  .s2 .tabs__caption {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    text-align: left; }
  .s2 .tabs__caption {
    margin-top: 27px;
    width: 100%;
    max-width: 250px; }
  .s2 .tabs__caption li {
    font-family: 'Uni Neue';
    font-weight: 700;
    color: #FFF;
    font-size: 16px;
    padding: 16px 0;
    padding-left: 47px; }
  .s2 .tabs__caption li:not(.active) {
    cursor: pointer; }
  .s2 .tabs__caption .active:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: -40px;
    height: 5px;
    background: #FFF; }
  .s2 .tabs__content {
    display: none; }
  .s2 .tabs__content.active {
    padding-left: 50px;
    padding-right: 90px;
    padding-top: 40px;
    display: flex;
    justify-content: space-between; }
    .s2 .tabs__content.active ul {
      max-width: 240px; }
      .s2 .tabs__content.active ul li {
        font-size: 14px;
        line-height: 24px;
        font-family: 'Uni Neue';
        font-weight: 400; }
      .s2 .tabs__content.active ul a {
        color: #7b909a; }
  .s2 .vertical .tabs__caption {
    float: left;
    display: block; }
  .s2 .vertical .tabs__caption li {
    float: none;
    border-width: 2px 0 2px 2px;
    border-radius: 5px 0 0 5px;
    display: flex;
    align-items: center; }
    .s2 .vertical .tabs__caption li span {
      display: block;
      margin-left: 5px;
      width: 20px;
      height: 20px;
      background-image: url(../img/arr_menu.png);
      background-repeat: no-repeat;
      background-position: center; }
  .s2 .vertical .tabs__caption li.active {
    background: rgba(0, 18, 30, 0.77); }
    .s2 .vertical .tabs__caption li.active span {
      background-image: url(../img/arr_menu_h.png); }
  .s2 .vertical .tabs__caption li:last-child:before {
    display: none; }
  .s2 .vertical .tabs__caption .active:after {
    left: auto;
    top: 0;
    right: 0px;
    bottom: 0;
    width: 2px;
    height: auto; }
  .s2 .vertical .tabs__content {
    overflow: hidden; }
  .s2 .catalog_link {
    cursor: pointer;
    transition: .4s all;
    font-family: 'Uni Neue';
    font-weight: 700;
    background: #fdc218;
    margin-left: 5px;
    border-radius: 5px;
    font-size: 16px; }
    .s2 .catalog_link:hover {
      background: #f9b900;
      color: #fff; }
    .s2 .catalog_link a {
      color: #00121e;
      display: flex;
      padding: 7px 17px;
      align-items: center; }
  .s2 .catalog_link.open {
    background: none; }
    .s2 .catalog_link.open a {
      color: #fdc218; }
    .s2 .catalog_link.open:hover {
      background: #f9b900;
      color: #fff; }
      .s2 .catalog_link.open:hover a {
        color: #fff; }
  .s2 .catalog_link.open .catalog__icon span {
    background: #fdc218; }
  .s2 .catalog_link.open .catalog__icon span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%; }
  .s2 .catalog_link.open .catalog__icon span:nth-child(2) {
    transform: rotate(45deg); }
  .s2 .catalog_link.open .catalog__icon span:nth-child(3) {
    transform: rotate(-45deg); }
  .s2 .catalog_link.open .catalog__icon span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%; }
  .s2 .catalog__icon {
    width: 14px;
    margin-left: 6px;
    margin-top: 5px;
    display: block;
    height: 12px;
    position: relative;
    cursor: pointer; }
  .s2 .catalog__icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #333333;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out; }
  .s2 .catalog__icon span:nth-child(1) {
    top: 0px; }
  .s2 .catalog__icon span:nth-child(2), .s2 .catalog__icon span:nth-child(3) {
    top: 4px;
    width: 12px; }
  .s2 .catalog__icon span:nth-child(4) {
    top: 8px;
    width: 10px; }
  .s2 .sub_menu ul {
    display: flex;
    margin-top: 7px;
    justify-content: space-between; }
    .s2 .sub_menu ul li {
      font-family: 'Uni Neue';
      font-weight: 700;
      transition: .4s all;
      padding-bottom: 3px; }
      .s2 .sub_menu ul li:hover {
        border-bottom: 1px solid #fff; }
      .s2 .sub_menu ul li a {
        color: #fff;
        font-size: 16px; }
  .s2 .search {
    margin-top: 5px;
    margin-left: -38px; }
    .s2 .search input {
      background-image: url(../img/search_icon.png);
      background-repeat: no-repeat;
      background-position: left center;
      width: 377px;
      background-color: transparent;
      border: none;
      border-bottom: 2px solid #fdc218;
      padding-bottom: 4px;
      color: #a9a9a9;
      padding-left: 20px;
      font-family: 'Uni Neue';
      font-style: italic;
      font-size: 14px;
      transition: .4s all; }
      .s2 .search input:focus {
        border-bottom: 2px solid #fff; }

.s3 {
  background-image: url(../img/bg3.png);
  background-repeat: no-repeat;
  background-position: center top; }
  .s3 .owl-carousel {
    padding-top: 80px;
    padding-bottom: 137px; }
  .s3 h1 {
    line-height: 70px;
    font-family: 'Gotham Pro Light';
    font-size: 60px;
    color: #fff; }
  .s3 p {
    margin-top: 30px;
    line-height: 20px;
    font-family: 'Uni Neue';
    font-weight: 400;
    font-size: 16px;
    color: #fff; }
  .s3 a {
    margin-top: 40px;
    display: block;
    width: max-content;
    font-family: "Uni Neue";
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fdc218;
    padding: 15px 54px;
    border-radius: 3px;
    border: 1px solid #fdc218; }
    .s3 a:hover {
      color: #fff;
      background: #fdc218; }
  .s3 .rb {
    display: flex;
    align-items: center;
    padding-top: 35px;
    padding-left: 35px; }
    .s3 .rb img {
      width: auto; }
  .s3 .owl-dots {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    margin: 0 auto;
    width: max-content; }
    .s3 .owl-dots .owl-dot {
      width: 13px;
      height: 13px;
      border: 1px solid #fff;
      border-radius: 50%;
      margin: 0 5px; }
    .s3 .owl-dots .owl-dot.active {
      background: #fff; }
  .s3 .owl-nav {
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%; }
    .s3 .owl-nav .owl-next {
      background-image: url(../img/arr.png) !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      height: 100%;
      width: 80px;
      position: absolute;
      right: 0;
      transition: .4s all;
      z-index: 2; }
      .s3 .owl-nav .owl-next:hover {
        background-color: rgba(255, 255, 255, 0.15); }
    .s3 .owl-nav .owl-prev {
      background-image: url(../img/arr.png) !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      height: 100%;
      width: 80px;
      position: absolute;
      left: -90px;
      transform: rotate(180deg);
      transition: .4s all;
      z-index: 2; }
      .s3 .owl-nav .owl-prev:hover {
        background-color: rgba(255, 255, 255, 0.15); }
  .s3 .owl-carousel .owl-stage-outer {
    z-index: 1; }

.s4 {
  background-image: url(../img/bg4.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding-top: 63px;
  padding-bottom: 50px; }
  .s4 .item {
    width: 180px;
    padding-left: 36px;
    border-left: 1px dashed #fff; }
    .s4 .item:nth-child(1) {
      margin-left: 0;
      padding-left: 0;
      border: none;
      width: 133px; }
    .s4 .item:nth-child(2) {
      padding-left: 35px; }
      .s4 .item:nth-child(2) img {
        margin-left: 5px; }
    .s4 .item:nth-child(3) {
      padding-left: 47px; }
      .s4 .item:nth-child(3) img {
        margin-left: 0px; }
    .s4 .item:nth-child(4) {
      width: 214px;
      padding-left: 67px; }
      .s4 .item:nth-child(4) img {
        margin-left: 0px; }
    .s4 .item:nth-child(5) {
      padding-left: 43px; }
      .s4 .item:nth-child(5) img {
        margin-left: 2px; }
    .s4 .item:nth-child(6) {
      padding-left: 63px; }
      .s4 .item:nth-child(6) img {
        margin-left: 0px; }
  .s4 h3 {
    font-family: 'Uni Neue';
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    margin-top: 14px;
    line-height: 20px; }

a.show_more {
  color: #00121e;
  font-family: "Uni Neue";
  font-size: 16px;
  font-weight: 700;
  border-radius: 3px;
  border: 1px solid #fdc218;
  float: right;
  margin-top: 45px;
  padding: 10px 17px; }
  a.show_more:hover {
    background: #fdc218;
    color: #fff; }

.s5 {
  padding-top: 60px; }
  .s5 .items {
    margin-top: 63px; }
    .s5 .items .item.size-x2 {
      position: relative;
      max-width: 450px;
      display: flex; }
      .s5 .items .item.size-x2:hover .price {
        background: none; }
      .s5 .items .item.size-x2:hover .buy {
        background: #fdc218; }
      .s5 .items .item.size-x2:hover .cart a {
        background-color: #00121e;
        background-image: url(../img/cart_h.png); }
      .s5 .items .item.size-x2 .buy {
        margin-top: 80px;
        position: absolute;
        bottom: 0;
        padding-bottom: 10px;
        padding-top: 10px;
        background: #fffbee;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .s5 .items .item.size-x2 .buy .count {
          align-items: center;
          margin-right: 80px; }
        .s5 .items .item.size-x2 .buy .kolvo {
          color: #00121e;
          font-family: "Uni Neue";
          font-size: 20px;
          font-weight: 700;
          width: 39px;
          height: 39px;
          border-radius: 3px;
          border: 1px solid #cccccc;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px; }
        .s5 .items .item.size-x2 .buy .plus {
          font-family: "Uni Neue";
          font-size: 18px;
          font-weight: 700;
          cursor: pointer; }
        .s5 .items .item.size-x2 .buy .min {
          font-family: "Uni Neue";
          font-size: 18px;
          font-weight: 700;
          cursor: pointer; }
      .s5 .items .item.size-x2 .cart a {
        background-image: url(../img/cart.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 39px;
        height: 39px;
        margin-right: 13px;
        margin-top: 5px;
        display: block;
        background-color: #fdc218;
        border-radius: 5px;
        padding: 10px; }
      .s5 .items .item.size-x2 .img {
        height: 330px;
        padding-top: 30px; }
        .s5 .items .item.size-x2 .img img {
          margin-left: 15px; }
        .s5 .items .item.size-x2 .img .skidka {
          top: 15px; }
      .s5 .items .item.size-x2 .old_price {
        font-size: 24px;
        font-family: 'Uni Neue';
        font-weight: 700;
        margin-top: 35px;
        margin-left: 28px;
        color: #fdc218;
        position: relative;
        width: max-content; }
        .s5 .items .item.size-x2 .old_price:before {
          content: '';
          width: 110%;
          position: absolute;
          left: -5%;
          top: 48%;
          height: 2px;
          background: #fdc218;
          display: block; }
      .s5 .items .item.size-x2 .price {
        font-size: 36px;
        margin-top: 0px;
        background: none; }
      .s5 .items .item.size-x2 h3 {
        font-size: 30px;
        margin-top: 60px; }
      .s5 .items .item.size-x2 p {
        margin-top: 23px; }
    .s5 .items .item {
      background: #fff;
      max-width: 218px;
      cursor: pointer;
      border: 1px solid #fff; }
      .s5 .items .item:hover {
        box-shadow: 0 0 10px rgba(253, 194, 24, 0.18);
        border: 1px solid #fdc218; }
        .s5 .items .item:hover .price {
          background: #fdc218; }
          .s5 .items .item:hover .price a {
            background-color: #00121e;
            background-image: url(../img/cart_h.png); }
      .s5 .items .item .img {
        height: 230px;
        width: 100%;
        position: relative;
        display: block;
        overflow: hidden; }
        .s5 .items .item .img img {
          display: block;
          margin: 7px auto 0; }
        .s5 .items .item .img .new {
          position: absolute;
          background: #28c621;
          color: #ffffff;
          font-family: 'Gotham Pro Regular';
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          top: 17px;
          padding: 8px; }
          .s5 .items .item .img .new:before {
            content: "";
            position: absolute;
            width: 0;
            display: block;
            height: 0;
            right: -10px;
            bottom: 0;
            border-bottom: 32px solid #28c621;
            border-right: 10px solid transparent; }
        .s5 .items .item .img .skidka {
          position: absolute;
          background: #ff0505;
          color: #ffffff;
          font-family: 'Gotham Pro Regular';
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          top: 17px;
          padding: 8px; }
          .s5 .items .item .img .skidka:before {
            content: "";
            position: absolute;
            width: 0;
            display: block;
            height: 0;
            right: -10px;
            bottom: 0;
            border-bottom: 32px solid #ff0505;
            border-right: 10px solid transparent; }
      .s5 .items .item h3 {
        font-size: 18px;
        font-family: 'Uni Neue';
        font-weight: 700;
        margin-left: 27px;
        margin-top: 20px;
        padding-right: 30px; }
      .s5 .items .item p {
        font-family: 'Uni Neue';
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-left: 27px;
        margin-top: 12px;
        padding-right: 20px; }
      .s5 .items .item .price {
        font-size: 24px;
        font-family: 'Uni Neue';
        font-weight: 700;
        margin-top: 23px;
        padding-top: 10px;
        padding-left: 27px;
        padding-bottom: 10px;
        background-color: #fffbee;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .s5 .items .item .price a {
          background-image: url(../img/cart.png);
          background-repeat: no-repeat;
          background-position: center;
          width: 39px;
          height: 39px;
          margin-right: 13px;
          margin-top: 5px;
          display: block;
          background-color: #fdc218;
          border-radius: 5px;
          padding: 10px; }

.s5#s6 {
  padding-top: 12px; }

.s5#s7 {
  padding-top: 12px; }

.s8 {
  background-image: url(../img/bg8.png);
  background-repeat: no-repeat;
  background-position: center top;
  margin-top: 63px;
  padding-top: 50px;
  padding-bottom: 23px; }
  .s8 .title {
    text-align: center; }
  .s8 .rb {
    position: relative;
    max-width: 467px;
    margin-top: 65px;
    margin-left: 50px; }
    .s8 .rb:before {
      width: 33px;
      height: 26px;
      content: "";
      display: block;
      background-image: url(../img/kav.png);
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 auto 20px; }
    .s8 .rb:after {
      width: 33px;
      height: 26px;
      content: "";
      display: block;
      background-image: url(../img/kav.png);
      background-repeat: no-repeat;
      background-position: center;
      margin: 20px auto 0px; }
    .s8 .rb p {
      color: #00121e;
      font-family: "Uni Neue";
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 30px; }
      .s8 .rb p:nth-child(1) {
        margin-top: 0; }
  .s8 .lb {
    margin-left: 100px;
    margin-top: 100px; }
    .s8 .lb .text {
      margin-top: 57px;
      margin-left: 50px; }
      .s8 .lb .text h3 {
        color: #00121e;
        font-family: "Uni Neue";
        font-size: 30px;
        font-weight: 700;
        line-height: 35px; }
      .s8 .lb .text img {
        max-width: 140px;
        display: block;
        margin: 30px auto 0; }
    .s8 .lb .ava {
      text-align: center; }
      .s8 .lb .ava .name {
        color: #00121e;
        font-family: "Uni Neue";
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin-top: 38px; }
        .s8 .lb .ava .name span {
          font-weight: 400;
          font-size: 14px; }
  .s8 .owl-nav {
    position: absolute;
    height: 100%;
    top: 0;
    width: 100%; }
    .s8 .owl-nav .owl-next {
      background-image: url(../img/arr.png) !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      height: 100%;
      width: 80px;
      position: absolute;
      right: 0;
      transition: .4s all;
      z-index: 2; }
      .s8 .owl-nav .owl-next:hover {
        background-color: rgba(0, 0, 0, 0.15); }
    .s8 .owl-nav .owl-prev {
      background-image: url(../img/arr.png) !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      height: 100%;
      width: 80px;
      position: absolute;
      left: 0px;
      transform: rotate(180deg);
      transition: .4s all;
      z-index: 2; }
      .s8 .owl-nav .owl-prev:hover {
        background-color: rgba(0, 0, 0, 0.15); }

.s9 {
  background: #fff;
  padding-top: 105px;
  padding-bottom: 117px; }
  .s9 .text {
    margin-top: 84px;
    padding-left: 70px;
    padding-right: 10px;
    position: relative; }
    .s9 .text:before {
      content: "";
      width: 30px;
      height: 30px;
      background: url(../img/i.png);
      background-repeat: no-repeat;
      background-position: center;
      display: block;
      position: absolute;
      left: 0px;
      z-index: 1; }
    .s9 .text:after {
      content: "";
      width: 2px;
      height: 98%;
      background: #fdc218;
      display: block;
      position: absolute;
      left: 15px;
      top: 5px; }
  .s9 p {
    font-family: "Uni Neue";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 24px; }
    .s9 p:nth-child(1) {
      margin-top: 0; }
  .s9 img {
    margin-top: 50px; }

footer {
  background-image: url(../img/footer.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding-top: 98px;
  padding-bottom: 12px; }
  footer .menu_bot {
    font-family: "Uni Neue";
    font-size: 18px;
    font-weight: 400; }
    footer .menu_bot ul li {
      margin-top: 18px; }
      footer .menu_bot ul li a {
        color: #fff; }
    footer .menu_bot ul:nth-child(2) {
      margin-left: 65px; }
  footer .contact {
    padding-left: 62px;
    padding-top: 7px; }
  footer .phone {
    margin-top: 10px; }
    footer .phone a {
      padding-left: 36px;
      display: block;
      background-image: url(../img/telephone.png);
      background-repeat: no-repeat;
      background-position: left center;
      font-family: 'Gotham Pro Regular';
      font-size: 18px;
      line-height: 30px;
      color: #fff; }
  footer .adr {
    margin-top: 30px;
    background-image: url(../img/maps.png);
    background-repeat: no-repeat;
    background-position: left top;
    color: #fff;
    font-family: "Uni Neue";
    font-size: 16px;
    line-height: 20px;
    padding-left: 35px; }
  footer .mail {
    margin-top: 12px; }
    footer .mail a {
      display: block;
      background-image: url(../img/mail.png);
      background-repeat: no-repeat;
      background-position: left center;
      font-family: "Uni Neue";
      font-size: 16px;
      color: #fff;
      padding: 5px 0;
      padding-left: 36px; }
  footer .copy {
    color: #fdc218;
    font-family: "Uni Neue";
    font-size: 14px;
    font-weight: 700;
    margin-top: 70px; }

.menu__icon {
  display: none;
  width: 20px;
  height: 25px;
  position: absolute;
  left: -220px;
  top: 25px;
  cursor: pointer; }

.menu__icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #333333;
  border-radius: 0px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out; }

.menu__icon span:nth-child(1) {
  top: 0px; }

.menu__icon span:nth-child(2), .menu__icon span:nth-child(3) {
  top: 5px; }

.menu__icon span:nth-child(4) {
  top: 10px; }

.menu__icon.menu_state_open {
  position: fixed; }

.menu_state_open.menu__icon span {
  background: #fff; }

.menu_state_open.menu__icon span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%; }

.menu_state_open.menu__icon span:nth-child(2) {
  transform: rotate(45deg); }

.menu_state_open.menu__icon span:nth-child(3) {
  transform: rotate(-45deg); }

.menu_state_open.menu__icon span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%; }

.menu_state_open.menu {
  display: block;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  width: 100%;
  top: 0px;
  left: 0; }
  .menu_state_open.menu ul {
    display: block;
    margin-top: 15px;
    margin-bottom: 15px; }
    .menu_state_open.menu ul li {
      border: none;
      width: max-content;
      margin: 0 auto; }
    .menu_state_open.menu ul a {
      color: #fff; }

.s10 {
  background: #fff;
  padding-bottom: 70px;
  /* секции аккордеона */
  /* заголовки панелей аккордеона */
  /* переключатель панелей, положение вниз */
  /* активный переключатель, положение вверх */
  /* заголовок активного блока  */
  /* блоки с содержанием */
  /* параграф внутри блоков с содержанием */
  /* картинки внутри аккордеона */
  /* полноразмерная картинка */
  /* миниатюра слева */
  /* миниатюра справа */ }
  .s10 .catalog_items {
    margin-top: 50px;
    padding-top: 40px;
    padding-bottom: 50px;
    -webkit-box-shadow: 0px 0px 11px 0px rgba(138, 133, 138, 0.39);
    -moz-box-shadow: 0px 0px 11px 0px rgba(138, 133, 138, 0.39);
    box-shadow: 0px 0px 11px 0px rgba(138, 133, 138, 0.39); }
    .s10 .catalog_items h3 {
      color: #00121e;
      font-size: 30px;
      font-family: 'Gotham Pro Black';
      text-transform: uppercase;
      text-align: center; }
  .s10 .accordion {
    width: 100%;
    max-width: 600px;
    margin: 33px auto 0; }
  .s10 .accordion .accordion_item {
    margin-bottom: 1px;
    position: relative; }
  .s10 .accordion .title_block {
    text-align: left;
    font-family: "Uni Neue";
    font-size: 18px;
    font-weight: 700;
    color: #00121e;
    cursor: pointer;
    padding: 10px 25px 10px 70px;
    -webkit-transition: all .2s linear 0;
    -webkit-transition-delay: .2s 0;
    transition: all .2s linear 0;
    text-transform: initial; }
  .s10 .accordion .active_block .title_block {
    background: #fdc218; }
  .s10 .accordion .title_block:before {
    content: '';
    height: 8px;
    width: 8px;
    display: block;
    border: 2px solid black;
    border-right-width: 0;
    border-top-width: 0;
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    right: 60px;
    top: 18px; }
  .s10 .accordion .active_block .title_block:before {
    border: 2px solid black;
    border-left-width: 0;
    border-bottom-width: 0;
    top: 18px; }
  .s10 .accordion .title_block:hover {
    background: #ecaf01; }
  .s10 .accordion .info {
    display: none;
    padding: 18px 30px 30px 85px;
    overflow: hidden; }
    .s10 .accordion .info ul {
      list-style-type: disc; }
      .s10 .accordion .info ul li {
        font-family: "Uni Neue";
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 17px; }
        .s10 .accordion .info ul li:nth-child(1) {
          margin-top: 0; }
        .s10 .accordion .info ul li a {
          color: #00121e; }
          .s10 .accordion .info ul li a strong {
            font-weight: 700; }
          .s10 .accordion .info ul li a span {
            color: #fdc218; }
  .s10 .accordion .info_item {
    margin-bottom: 10px; }
  .s10 .accordion .info img {
    height: auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
  .s10 .large-img {
    width: 100%;
    margin-bottom: 10px; }
  .s10 .img-left {
    width: 25%;
    float: left;
    margin: 5px 15px 5px 0; }
  .s10 .img-right {
    width: 25%;
    float: right;
    margin: 5px 0 5px 15px; }
  .s10 .items {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    padding-left: 23px; }
  .s10 .item {
    background: #fff;
    max-width: 218px;
    cursor: pointer;
    margin-top: 25px;
    margin-left: 25px;
    border: 1px solid #f5f5f5; }
    .s10 .item:hover {
      box-shadow: 0 0 10px rgba(253, 194, 24, 0.18);
      border: 1px solid #fdc218; }
      .s10 .item:hover .price {
        background: #fdc218; }
        .s10 .item:hover .price a {
          background-color: #00121e;
          background-image: url(../img/cart_h.png); }
    .s10 .item .img {
      height: 230px;
      width: 100%;
      position: relative;
      display: block;
      overflow: hidden; }
      .s10 .item .img img {
        display: block;
        margin: 0px auto 0; }
      .s10 .item .img .new {
        position: absolute;
        background: #28c621;
        color: #ffffff;
        font-family: 'Gotham Pro Regular';
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        top: 17px;
        padding: 8px; }
        .s10 .item .img .new:before {
          content: "";
          position: absolute;
          width: 0;
          display: block;
          height: 0;
          right: -10px;
          bottom: 0;
          border-bottom: 32px solid #28c621;
          border-right: 10px solid transparent; }
      .s10 .item .img .skidka {
        position: absolute;
        background: #ff0505;
        color: #ffffff;
        font-family: 'Gotham Pro Regular';
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        top: 17px;
        padding: 8px; }
        .s10 .item .img .skidka:before {
          content: "";
          position: absolute;
          width: 0;
          display: block;
          height: 0;
          right: -10px;
          bottom: 0;
          border-bottom: 32px solid #ff0505;
          border-right: 10px solid transparent; }
    .s10 .item h3 {
      font-size: 18px;
      font-family: 'Uni Neue';
      font-weight: 700;
      margin-left: 27px;
      margin-top: 20px;
      padding-right: 30px; }
    .s10 .item p {
      font-family: 'Uni Neue';
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin-left: 27px;
      margin-top: 12px;
      padding-right: 20px; }
    .s10 .item .price {
      font-size: 24px;
      font-family: 'Uni Neue';
      font-weight: 700;
      margin-top: 23px;
      padding-top: 10px;
      padding-left: 27px;
      padding-bottom: 10px;
      background-color: #fffbee;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .s10 .item .price a {
        background-image: url(../img/cart.png);
        background-repeat: no-repeat;
        background-position: center;
        width: 39px;
        height: 39px;
        margin-right: 13px;
        margin-top: 5px;
        display: block;
        background-color: #fdc218;
        border-radius: 5px;
        padding: 10px; }

.s4#s11 {
  background-image: url(../img/b11.png);
  padding-top: 96px;
  padding-bottom: 80px; }
  .s4#s11 h3 {
    color: #00121e; }

.s12 {
  padding-top: 33px;
  background: #fff;
  padding-bottom: 90px; }
  .s12 .teh_opisanie {
    background: #00121e;
    width: max-content;
    margin-top: 40px; }
    .s12 .teh_opisanie a {
      margin-left: 75px;
      color: #fdc218;
      font-family: 'Uni Neue';
      font-weight: 400;
      position: relative;
      font-size: 18px;
      padding: 25px 19px;
      display: block;
      position: relative; }
      .s12 .teh_opisanie a:before {
        display: block;
        content: "";
        padding: 34px;
        background-position: center;
        top: 0;
        left: -75px;
        background-image: url(../img/pdf.png);
        background-color: #fdc218;
        background-repeat: no-repeat;
        position: absolute; }
    .s12 .teh_opisanie:hover {
      background: #053556; }
  .s12 .thumbelina-but.vert.top, .s12 .thumbelina-but.vert.bottom {
    border: none;
    box-shadow: none;
    border-radius: 0; }
  .s12 .thumbelina-but.vert {
    left: -1px;
    height: 20px;
    line-height: 27px;
    width: 66px;
    margin-left: 5px; }
  .s12 .align-center {
    margin-top: 35px;
    align-items: center;
    justify-content: space-between; }
    .s12 .align-center .img {
      width: 100%; }
      .s12 .align-center .img img {
        display: block;
        margin: 0 auto; }
  .s12 .pagination a {
    font-family: 'Uni Neue';
    font-weight: 400;
    font-size: 14px;
    color: #bbbbbb;
    margin-left: 10px; }
    .s12 .pagination a:nth-child(1) {
      margin-left: 0; }
    .s12 .pagination a:last-child {
      font-weight: 700;
      color: #00121e; }
  .s12 h2.cart {
    font-family: 'Uni Neue';
    font-weight: 400;
    font-size: 36px;
    color: #00121e;
    margin-top: 34px; }
  .s12 #slider {
    position: relative;
    margin-top: 20px;
    width: 93px;
    height: 340px;
    margin-bottom: 20px; }
    .s12 #slider a {
      cursor: pointer; }
    .s12 #slider img {
      width: 100%; }
  .s12 .description {
    margin-left: 165px;
    margin-top: 35px; }
    .s12 .description .art {
      font-size: 12px;
      font-family: 'Uni Neue';
      font-weight: 400;
      color: #7f7f7f;
      text-align: center;
      margin-top: 10px; }
    .s12 .description .litr {
      text-align: center;
      background: #fffbee;
      border: 1px solid #fffbee;
      font-size: 24px;
      font-family: 'Uni Neue';
      font-weight: 700;
      color: #00121e;
      padding: 10px 0; }
    .s12 .description .item {
      margin-left: 33px;
      cursor: pointer; }
      .s12 .description .item:nth-child(1) {
        margin-left: 0; }
    .s12 .description .item.selected .litr {
      border: 1px solid #fdc218; }
    .s12 .description .one_click_order {
      background-color: #fdc218;
      width: max-content;
      margin-top: 47px;
      border-radius: 4px;
      transition: .4s all; }
      .s12 .description .one_click_order:hover {
        background-color: #fbbb03; }
      .s12 .description .one_click_order a {
        padding: 14px 63px 14px 23px;
        display: block;
        color: #00121e;
        font-size: 18px;
        font-family: 'Uni Neue';
        font-weight: 700;
        background-image: url(../img/shopping-cart.png);
        background-repeat: no-repeat;
        background-position: 220px center; }
    .s12 .description p {
      font-size: 14px;
      font-family: 'Uni Neue';
      font-weight: 400;
      line-height: 20px;
      color: #00121e;
      margin-top: 45px;
      max-width: 520px; }
  .s12 #dop_info {
    margin-top: 35px;
    color: #fdc218;
    font-size: 24px;
    font-family: 'Uni Neue';
    font-weight: 400;
    position: relative; }
    .s12 #dop_info:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background: #fdc218;
      bottom: 4px;
      left: 330px; }
  .s12 .overflov-hidden {
    overflow: hidden; }
  .s12 .dop_info {
    margin-top: 33px; }
    .s12 .dop_info a {
      display: block;
      height: 50px;
      color: #00121e;
      background-image: url(../img/pdf.png);
      background-repeat: no-repeat;
      background-position: left center;
      display: flex;
      align-items: center;
      font-size: 18px;
      padding-left: 55px;
      margin-top: 17px; }
      .s12 .dop_info a:nth-child(1) {
        margin-top: 0; }

@media (max-width: 1300px) {
  .s3 .owl-nav .owl-prev {
    left: 0; } }

@media (max-width: 1200px) {
  .s3 .owl-nav .owl-prev {
    left: 0; }
  .s3 .item .lb {
    margin-left: 90px; }
  .s8 .lb .text {
    margin-top: 45px;
    margin-left: 18px; }
  .s9 .text {
    margin-top: 57px; }
  footer .menu_bot ul:nth-child(2) {
    margin-left: 20px; }
  footer .menu_bot {
    font-size: 16px; }
  footer .adr p br {
    display: none; }
  header .menu ul {
    margin-left: 0;
    justify-content: flex-start; }
  header .logo {
    max-width: 150px;
    margin-top: 15px; }
    header .logo img {
      width: 100%; }
  .s2 .search input {
    width: 270px; }
  .s9 img {
    width: 100%; } }

@media (min-width: 576px) {
  .tabs {
    width: 540px !important; } }

@media (min-width: 768px) {
  .tabs {
    width: 720px !important; } }

@media (min-width: 992px) {
  .tabs {
    width: 960px !important; } }

@media (min-width: 1200px) {
  .tabs {
    width: 1175px !important; } }

@media (max-width: 992px) {
  .menu__icon {
    display: inline-block; }
  .s2 .tabs__content.active ul li {
    font-size: 12px;
    line-height: 17px; }
  .s2 .tabs__content.active {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 25px; }
  .s2 .tabs__caption li {
    font-size: 12px;
    padding: 10px 0;
    padding-left: 15px; }
  .s2 .tabs__caption {
    max-width: 170px; }
  .menu__links {
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    left: 0;
    margin-top: 52px;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    overflow: auto; }
  .menu__links-item {
    display: block;
    padding: 10px 0;
    text-align: center;
    color: #ffffff; }
  .s3 .owl-nav .owl-prev {
    left: 0; }
  header .menu {
    display: none; }
  .s2 .search input {
    width: 117px; }
  .s2 .search {
    margin-left: -80px; }
  .s2 .sub_menu ul li a {
    color: #fff;
    font-size: 13px; }
  .sub_menu {
    margin-left: -60px; }
  .s3 .rb img {
    width: 100%; }
  .s3 h1 {
    line-height: 30px;
    font-family: 'Gotham Pro Light';
    font-size: 27px;
    color: #fff; }
  .s3 p {
    margin-top: 19px;
    line-height: 17px;
    font-family: 'Uni Neue';
    font-weight: 400;
    font-size: 15px;
    color: #fff; }
  .s4 .item {
    padding: 0 8px !important; }
  .s4 h3 {
    font-size: 13px; }
  .s5 .items.d-flex {
    display: block !important; }
  .s5 .items .item {
    margin: 10px auto; }
  .s5 .items .item.size-x2 {
    margin-bottom: 80px; }
  h2.title {
    text-align: center; }
  a.show_more {
    float: none;
    margin: 20px auto;
    display: block;
    width: max-content; }
  .s5 .items .item.size-x2 .buy {
    margin-bottom: -65px; }
  .s8 {
    background-size: cover; }
  .s8 .d-flex {
    display: block !important;
    text-align: center; }
  .s8 .lb {
    text-align: center;
    margin: 0 auto; }
  .s8 .lb .ava {
    max-width: 100px;
    margin: 20px auto 0; }
  .s8 .lb .ava .name {
    margin-top: 10px; }
  .s8 .lb .text {
    margin-top: 10px; }
  .s8 .rb {
    margin: 15px auto 0; }
  footer .logo img {
    width: 100%; }
  footer .menu_bot {
    display: block !important; }
  footer .menu_bot ul:nth-child(2) {
    margin-left: 0; }
  footer .contact {
    padding-left: 0; }
  footer .phone a {
    font-size: 15px; }
  footer .logo img {
    width: 100%;
    max-width: 150px; }
  .s3 {
    background-size: cover; }
  .s10 .catalog_items h3 {
    font-size: 20px; }
  .s10 .catalog_items {
    padding: 20px 0; }
  .s10 .accordion .title_block {
    padding: 10px 10px 10px 12px; }
  .s10 .accordion .title_block:before {
    right: 20px; }
  .s10 .accordion .info {
    padding: 18px 13px 30px 25px; }
  .s10 .accordion .info ul li {
    font-family: "Uni Neue";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 10px; }
  .s10 .item {
    margin-left: 5px; }
  .s10 .items {
    padding-left: 0; }
  .s12 .align-center .img img {
    width: 100%;
    max-width: 300px; }
  .s12 .dop_info a {
    margin-top: 30px; }
    .s12 .dop_info a br {
      display: none; }
  .s12 .description {
    margin: 30px auto 0; } }

@media (max-width: 768px) {
  .s10 .items {
    justify-content: space-between; }
  .s2 .tabs__content.active {
    display: block; }
  .menu__icon {
    left: 20px;
    z-index: 5; }
  header {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px; }
  header .phone a {
    width: max-content;
    margin: 0 auto; }
  header .logo {
    margin: 0 auto; }
  .s2 .catalog_link a {
    width: max-content; }
  .s2 .catalog_link {
    width: max-content;
    margin-left: 0; }
  .sub_menu {
    margin-left: 0; }
  .s2 .search {
    margin-left: 0; }
  .s2 .justify-content-between {
    justify-content: flex-start !important; }
  .s2 .search input {
    width: 100%; }
  .s4 .d-flex {
    display: block !important; }
  .s4 .item {
    border: none;
    text-align: center;
    margin: 0 auto !important; }
  .s4 {
    padding: 20px 0; }
  .s5 {
    padding-top: 20px; }
  .s5 .items {
    margin-top: 20px; }
  footer {
    padding-top: 20px; }
  .s9 {
    padding: 20px 0; }
  .s8 {
    padding: 20px 0; }
  .s3 .d-flex {
    display: block !important; }
  .s3 .item .lb {
    text-align: center;
    margin-left: 0; }
  .s3 a {
    margin: 20px auto 0; }
  .s3 {
    background-position: center; } }

@media (max-width: 580px) {
  .s2 .vertical .tabs__caption {
    float: none; }
  .s2 .tabs__caption {
    width: 100%;
    max-width: 100%; }
  .s12 .teh_opisanie a {
    padding: 25px 5px; } }

@media (max-width: 493px) {
  .s10 .items {
    justify-content: center; } }

@media (max-width: 480px) {
  .s2 .sub_menu ul li a {
    color: #fff;
    font-size: 9px; }
  .s3 .owl-carousel {
    padding: 30px 0; }
  .s3 .owl-nav .owl-next {
    width: 30px; }
  .s3 .owl-nav .owl-prev {
    width: 30px; }
  .s5 .items .item.size-x2 {
    display: block !important; }
  footer .copy {
    margin-top: 20px; } }
