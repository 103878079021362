@font-face {
	font-family: 'Gotham Pro Regular';
	src: url('fonts/GothamPro.eot');
	src: url('fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
	url('fonts/GothamPro.woff') format('woff'),
	url('fonts/GothamPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Gotham Pro Light';
	src: url('fonts/ofont.ru_Gotham Pro.ttf');
	src: url('fonts/ofont.ru_Gotham Pro.ttf?#iefix') format('embedded-opentype'),
	url('fonts/ofont.ru_Gotham Pro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro Black';
	src: url('fonts/GothamPro-Black.eot');
	src: url('fonts/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
	url('fonts/GothamPro-Black.woff') format('woff'),
	url('fonts/GothamPro-Black.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Regular'), local('Uni-Neue-Regular'),
        url('fonts/UniNeueRegular/UniNeueRegular.woff2') format('woff2'),
        url('fonts/UniNeueRegular/UniNeueRegular.woff') format('woff'),
        url('fonts/UniNeueRegular/UniNeueRegular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Bold'), local('Uni-Neue-Bold'),
        url('fonts/UniNeueBold/UniNeueBold.woff2') format('woff2'),
        url('fonts/UniNeueBold/UniNeueBold.woff') format('woff'),
        url('fonts/UniNeueBold/UniNeueBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Uni Neue';
    src: local('Uni Neue Regular Italic'), local('Uni-Neue-Regular-Italic'),
        url('fonts/UniNeueRegular-Italic/UniNeueRegular-Italic.woff2') format('woff2'),
        url('fonts/UniNeueRegular-Italic/UniNeueRegular-Italic.woff') format('woff'),
        url('fonts/UniNeueRegular-Italic/UniNeueRegular-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }

