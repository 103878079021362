@import 'reset';
@import 'fonts';

$black:#00121e;
body {
	background: #f5f5f5;
}
a{
	text-decoration: none;
}

@media (min-width: 1200px){

	.container {
	    max-width: 1175px;
	}
}

h2.title{
	color: #00121e;
	font-size: 30px;
	font-weight: 400;
	text-transform: uppercase;
	font-family: 'Gotham Pro Black';
}

//Шапка сайта ----------------------------------

header{
	background:#fff; 
	padding-top: 33px;
	padding-bottom: 29px;

	.phone{
		margin-top: 1px;
		a{
			padding-left: 33px;
			display: block;
			background-image: url(../img/telephone.png);
			background-repeat: no-repeat;
			background-position: left center;
			font-family: 'Gotham Pro Regular';
			font-size: 18px;
			line-height: 30px;
			color: $black;
		}
	}

	.menu{
	
		ul{
			display: flex;
			justify-content: space-around;
			font-family: 'Uni Neue';
			font-weight: 700;
			text-transform: uppercase;
			font-size: 16px;
			margin-top: 22px;
			margin-left: 10px;
			li{
				    border: 1px solid #fff;
				    transition: .4s all;


    				padding: 4px 7px;

    				&:hover{
    					border: 1px solid #fdc218;
    				}
			}
			a{
				color: $black;
			}
		}
	}
}


//Меню и кнопка каталог (s2)---------------------------------

.s2{
	.tabs__content {
	  display: none; /* по умолчанию прячем все блоки */
	}
	.tabs__content.active {
	  display: block; /* по умолчанию показываем нужный блок */
	}

	.catalog_sub_menu{
		
		
		width: 100%;
	}

	.tabs {
		position: absolute;
		display: none;
		z-index: 2;
		left: 0px;
		width: 1175px;
		margin: 0 0 30px;
		background: rgba(0,19,30,.8);
		padding-bottom: 45px;
	}
	.tabs.open{
		display: block;
	}
	.tabs__caption {
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-flex-wrap: wrap;
	  -ms-flex-wrap: wrap;
	  flex-wrap: wrap;
	  list-style: none;
	  position: relative;
	  text-align: left;

	}


	.tabs__caption {
		margin-top: 27px;
		width: 100%;
    	max-width: 250px;
	}
	.tabs__caption li {
		font-family: 'Uni Neue';
		font-weight: 700;
		color: #FFF;
		font-size: 16px;
		padding: 16px 0;
		padding-left: 47px;

	}
	.tabs__caption li:not(.active) {
	  cursor: pointer;
	}
	.tabs__caption li:not(.active):hover {

	}
	.tabs__caption .active {

	}
	.tabs__caption .active:after {
	  content: '';
	  position: absolute;
	  bottom: -5px;
	  left: 0;
	  right: -40px;
	  height: 5px;
	  background: #FFF;
	}
	.tabs__content {
	  display: none;
	  
	}
	.tabs__content.active {
		padding-left: 50px;
		padding-right: 90px;
		padding-top: 40px;
	  display: flex;
	  justify-content: space-between;

	  ul{
		max-width: 240px;
		li{
			font-size: 14px;
			line-height: 24px;
			font-family: 'Uni Neue';
			font-weight: 400;
		}
	  	a{
	  		color: #7b909a;
	  	}
	  }
	}

	.vertical .tabs__caption {
	  float: left;
	  display: block;
	}

	.vertical .tabs__caption li {
	  float: none;
	  border-width: 2px 0 2px 2px;
	  border-radius: 5px 0 0 5px;
	  display: flex;
	  align-items: center;
	  span{
	  	display: block;
	  	margin-left: 5px;
	  	width: 20px;
	  	height: 20px;
	  	background-image: url(../img/arr_menu.png);
	  	background-repeat: no-repeat;
	  	background-position: center;
	  }
	}
	.vertical .tabs__caption li.active{
		background: rgba(0,18,30,.77);
		span{
			background-image: url(../img/arr_menu_h.png);
		}
	}
	.vertical .tabs__caption li:last-child:before {
	  display: none;
	}
	.vertical .tabs__caption .active:after {
	  left: auto;
	  top: 0;
	  right: 0px;
	  bottom: 0;
	  width: 2px;
	  height: auto;
	}
	.vertical .tabs__content {
	  overflow: hidden;
	}


	background: #00121e;
	padding-top: 14px;
	padding-bottom: 14px;

	.catalog_link{
		cursor: pointer;
		transition: .4s all;
		font-family: 'Uni Neue';
		font-weight: 700;
		background: #fdc218;
		margin-left: 5px;
		border-radius: 5px;
		font-size: 16px;

		&:hover{
			background: #f9b900;
			color: #fff;
		}	

		a{
			color: $black;
			display: flex;
			padding: 7px 17px;
			align-items: center;
		}
	}

	.catalog_link.open{
		background: none;
		a{
			color: #fdc218;
		}
		&:hover{
			background: #f9b900;
			color: #fff;

			a{
				color: #fff;
			}
		}
	}
	.catalog_link.open{

		.catalog__icon span{
			background: #fdc218;
		}
		.catalog__icon span:nth-child(1) {
		    top: 18px;
		    width: 0%;
		    left: 50%;
		}

		.catalog__icon span:nth-child(2) {
		    transform: rotate(45deg);
		}

		.catalog__icon span:nth-child(3) {
		    transform: rotate(-45deg);
		}

		.catalog__icon span:nth-child(4) {
		    top: 18px;
		    width: 0%;
		    left: 50%;
		}

	}


	.catalog__icon {
	    width: 14px;
	    margin-left: 6px;
	    margin-top: 5px;
	    display: block;
	    height: 12px;
	    position: relative;
	    cursor: pointer;

	}

	.catalog__icon span {
	    display: block;
	    position: absolute;
	    height: 2px;
	    width: 100%;
	    background: #333333;
	    border-radius: 9px;
	    opacity: 1;
	    left: 0;
	    transform: rotate(0deg);
	    transition: .25s ease-in-out;
	}

	.catalog__icon span:nth-child(1) {
	    top: 0px;
	}

	.catalog__icon span:nth-child(2), .catalog__icon span:nth-child(3) {
	    top: 4px;
	    width: 12px;
	}

	.catalog__icon span:nth-child(4) {
	    top: 8px;
	    width: 10px;
	}


	.sub_menu{
		ul{
			display: flex;
			margin-top: 7px;
			
			justify-content: space-between;
			li{
				font-family: 'Uni Neue';
				font-weight: 700;
				transition: .4s all;
				padding-bottom: 3px;

				&:hover{
					border-bottom: 1px solid #fff;
				}

				a{
					color: #fff;
					font-size: 16px;
				}
			}
		}
	}

	.search{
		margin-top: 5px;
		margin-left: -38px;
		input{
			background-image: url(../img/search_icon.png);
			background-repeat: no-repeat;
			background-position: left center;
			width: 377px;
			background-color: transparent;
			border: none;
			border-bottom: 2px solid #fdc218;
			padding-bottom: 4px;
			color: #a9a9a9;
			padding-left: 20px;
			font-family: 'Uni Neue';
			font-style: italic;
			font-size: 14px;
			transition: .4s all;

			&:focus{
				border-bottom: 2px solid #fff;
			}
		}
	}


}


.s3{
	background-image: url(../img/bg3.png);
	background-repeat: no-repeat;
	background-position: center top;
	
	.owl-carousel{
		padding-top: 80px;
		padding-bottom: 137px;
	}
	h1{
		line-height: 70px;
		font-family: 'Gotham Pro Light';
		font-size: 60px;
		color: #fff;
	}
	p{
		margin-top: 30px;
		line-height: 20px;
		font-family: 'Uni Neue';
		font-weight: 400;
		font-size: 16px;
		color: #fff;
	}

	a{
		margin-top: 40px;
		display: block;
		width: max-content;
		font-family: "Uni Neue";
		font-size: 18px;
		font-weight: 400;
		text-transform: uppercase;
		color: #fdc218;
		padding: 15px 54px;
		border-radius: 3px;
		border: 1px solid #fdc218;

		&:hover{
			color: #fff;
			background: #fdc218;
		}
	}

	.rb{
		display: flex;
		align-items: center;
		padding-top: 35px;
		padding-left: 35px;
		img{
			width: auto;
		}
	}
	.owl-dots{
		display: flex;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 10px;
		margin: 0 auto;
		width: max-content;
		.owl-dot{
			width: 13px;
			height: 13px;
			border: 1px solid #fff;
			border-radius: 50%;
			margin: 0 5px;
		}
		.owl-dot.active{
			background: #fff;
		}
	}
	.owl-nav{
		position: absolute;
		height: 100%;
		top: 0;
		width: 100%;

		.owl-next{
			background-image: url(../img/arr.png)!important;
			background-position: center!important;
			background-repeat: no-repeat!important;
			height: 100%;
			width: 80px;
			position: absolute;
			right: 0;
			transition: .4s all;
			    z-index: 2;
			&:hover{
				background-color: rgba(255,255,255,.15);
			}

		}

		.owl-prev{
			background-image: url(../img/arr.png)!important;
			background-position: center!important;
			background-repeat: no-repeat!important;
			height: 100%;
			width: 80px;
			position: absolute;
			left: -90px;
			transform: rotate(180deg);
			transition: .4s all;
			    z-index: 2;
			&:hover{
				background-color: rgba(255,255,255,.15);
			}

		}


	}

	.owl-carousel .owl-stage-outer{
		z-index: 1;
	}

}



.s4{
	background-image: url(../img/bg4.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	padding-top: 63px;
	padding-bottom: 50px;

	.item{
		width: 180px;
		padding-left: 36px;
		border-left: 1px dashed #fff;

		&:nth-child(1){
			margin-left: 0;
			padding-left: 0;
			border: none;
			width: 133px;
		}
		&:nth-child(2){
			padding-left: 35px;
			img{
				margin-left: 5px;
			}
		}
		&:nth-child(3){
			padding-left: 47px;
			img{
				margin-left: 0px;
			}
		}
		&:nth-child(4){
			width: 214px;
			padding-left: 67px;
			img{
				margin-left: 0px;
			}
		}
		&:nth-child(5){
			padding-left: 43px;
			img{
				margin-left: 2px;
			}
		}
		&:nth-child(6){
			padding-left: 63px;
			img{
				margin-left: 0px;
			}
		}
	}

	h3{
		font-family: 'Uni Neue';
		font-weight: 700;
		color: #fff;
		font-size: 16px;
		margin-top: 14px;
		line-height: 20px;
	}
}


a.show_more{
	color: #00121e;
	font-family: "Uni Neue";
	font-size: 16px;
	font-weight: 700;
	border-radius: 3px;
	border: 1px solid #fdc218;
	float: right;
	margin-top: 45px;
	padding: 10px 17px;

	&:hover{
		background: #fdc218;
		color: #fff;
	}
}


.s5{
	padding-top: 60px;
	
	.items{
		margin-top: 63px;

		.item.size-x2{
			position: relative;
			max-width: 450px;
			display: flex;

			&:hover{
				.price{
					background: none;					
				}
				.buy{
					background: #fdc218;					
				}

				.cart a{
					background-color: #00121e;
					background-image: url(../img/cart_h.png);
				}
			}

			.buy{
				margin-top: 80px;
				position: absolute;
				bottom: 0;
				padding-bottom: 10px;
				padding-top: 10px;
				background: #fffbee;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				
				.count{
					align-items: center; 
					margin-right: 80px;
				}
				.kolvo{
					color: #00121e;
					font-family: "Uni Neue";
					font-size: 20px;
					font-weight: 700;
					width: 39px;
					height: 39px;
					border-radius: 3px;
					border: 1px solid #cccccc;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 10px;
				}

				.plus{
					font-family: "Uni Neue";
					font-size: 18px;
					font-weight: 700;
					cursor: pointer;
				}
				.min{
					font-family: "Uni Neue";
					font-size: 18px;
					font-weight: 700;
					cursor: pointer;
				}
			}

			.cart{
				a{
					background-image: url(../img/cart.png);
					background-repeat: no-repeat;
					background-position: center;
					width: 39px;
					height: 39px;
					margin-right: 13px;
					margin-top:5px;
					display: block;
					background-color: #fdc218;
					border-radius: 5px;
					padding: 10px;
				}
			}

			.img{
				height: 330px;
				padding-top: 30px;
				img{
					margin-left: 15px;
				}
				.skidka{
					top: 15px;
				}
				
			}
				
			.old_price{
				font-size: 24px;
			    font-family: 'Uni Neue';
			    font-weight: 700;
			    margin-top: 35px;
			    margin-left: 28px;
			    color: #fdc218;
			    position: relative;
			    width: max-content;
			    &:before{
					content: '';
					width: 110%;
					position: absolute;
					left: -5%;
					top: 48%;
					height: 2px;
					background: #fdc218;
					display: block;
			    }
			}
			.price{
				font-size: 36px;
				margin-top: 0px;
				background: none;
			}

			h3{
				font-size: 30px;
				margin-top: 60px;
			}
			p{
				margin-top: 23px;
			}
		}
		.item{
			background: #fff;
			max-width: 218px;
			cursor: pointer;
			
			border: 1px solid #fff;
			
			&:hover{
				box-shadow: 0 0 10px rgba(253, 194, 24, 0.18);
				border: 1px solid #fdc218;

				.price{
					background: #fdc218;

					a{
						background-color: #00121e;
						background-image: url(../img/cart_h.png);
					}
				}
			}	
			
			.img{
				height: 230px;
				width: 100%;
				position: relative;
				display: block;
				overflow: hidden;

				img{
					display: block;
					margin: 7px auto 0;
				}

				.new{
					position: absolute;
					background: #28c621;
					color: #ffffff;
					font-family: 'Gotham Pro Regular';
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					top: 17px;
					padding: 8px;

					&:before{
						content: "";
						position: absolute;
						width: 0;
						display: block;
						height: 0;
						right: -10px;
						bottom: 0;
						border-bottom: 32px solid #28c621; 
						border-right:10px solid transparent;	
					}
				}

				.skidka{
					position: absolute;
					background: #ff0505;
					color: #ffffff;
					font-family: 'Gotham Pro Regular';
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					top: 17px;
					padding: 8px;

					&:before{
						content: "";
						position: absolute;
						width: 0;
						display: block;
						height: 0;
						right: -10px;
						bottom: 0;
						border-bottom: 32px solid #ff0505; 
						border-right:10px solid transparent;	
					}
				}



			}
			

			h3{
				font-size: 18px;
				font-family: 'Uni Neue';
				font-weight: 700;
				margin-left: 27px;
				margin-top: 20px;
				padding-right: 30px;
			}
			p{
				font-family: 'Uni Neue';
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				margin-left: 27px;
				margin-top: 12px;
				padding-right: 20px;
			}

			.price{
				font-size: 24px;
				font-family: 'Uni Neue';
				font-weight: 700;
				margin-top: 23px;
				padding-top: 10px;
				padding-left: 27px;
				padding-bottom: 10px;
				background-color: #fffbee;

				display: flex;
				align-items: center;
				justify-content: space-between;

				a{
					background-image: url(../img/cart.png);
					background-repeat: no-repeat;
					background-position: center;
					width: 39px;
					height: 39px;
					margin-right: 13px;
					margin-top:5px;
					display: block;
					background-color: #fdc218;
					border-radius: 5px;
					padding: 10px;
				}
			}

		}
	}
}

.s5#s6{
	padding-top: 12px;
}

.s5#s7{
	padding-top: 12px;
}

.s8{
	background-image: url(../img/bg8.png);
	background-repeat: no-repeat;
	background-position: center top;
	margin-top: 63px;
	padding-top: 50px;
	padding-bottom: 23px;
	.title{
		text-align: center;
	}
	.rb{
		position: relative;
		max-width: 467px;
		margin-top: 65px;
		margin-left: 50px;

		&:before{
			width: 33px;
			height: 26px;
			content: "";
			display: block;
			background-image: url(../img/kav.png);
			background-repeat: no-repeat;
			background-position: center;
			margin: 0 auto 20px;
		}
		&:after{
			width: 33px;
			height: 26px;
			content: "";
			display: block;
			background-image: url(../img/kav.png);
			background-repeat: no-repeat;
			background-position: center;
			margin: 20px auto 0px;
		}

		p{
			color: #00121e;
			font-family: "Uni Neue";
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			margin-top: 30px;

			&:nth-child(1){
				margin-top: 0;
			}
		}
	}

	.lb{
		margin-left: 100px; 
		margin-top: 100px;

		.text{
			margin-top: 57px;
			margin-left: 50px;

			h3{
				color: #00121e;
				font-family: "Uni Neue";
				font-size: 30px;
				font-weight: 700;
				line-height: 35px;
			}

			img{
				max-width: 140px;
				display:block;
				margin: 30px auto 0;
			}
		}

		.ava{
			text-align: center;

			.name{
				color: #00121e;
				font-family: "Uni Neue";
				font-size: 18px;
				font-weight: 700;
				line-height: 24px;
				margin-top: 38px;

				span{
					font-weight: 400;
					font-size: 14px;		
				}
			}

			
		}
	}

.owl-nav{
		position: absolute;
		height: 100%;
		top: 0;
		width: 100%;

		.owl-next{
			background-image: url(../img/arr.png)!important;
			background-position: center!important;
			background-repeat: no-repeat!important;
			height: 100%;
			width: 80px;
			position: absolute;
			right: 0;
			transition: .4s all;
			    z-index: 2;
			&:hover{
				background-color: rgba(0,0,0,.15);
			}

		}

		.owl-prev{
			background-image: url(../img/arr.png)!important;
			background-position: center!important;
			background-repeat: no-repeat!important;
			height: 100%;
			width: 80px;
			position: absolute;
			left: 0px;
			transform: rotate(180deg);
			transition: .4s all;
			    z-index: 2;
			&:hover{
				background-color: rgba(0,0,0,.15);
			}

		}


	}


}

.s9{
	background: #fff;
	padding-top: 105px;
	padding-bottom: 117px;
	.text{
		margin-top: 84px;
		padding-left: 70px;
		padding-right: 10px;
		position: relative;

		&:before{
			content: "";
			width: 30px;
			height: 30px;
			background: url(../img/i.png);
			background-repeat: no-repeat;
			background-position: center;
			display: block;
			position: absolute;
			left: 0px;
			z-index: 1;
		}
		&:after{
			content: "";
			width: 2px;
			height: 98%;
			background: #fdc218;
			display: block;
			position: absolute;
			left: 15px;
			top: 5px;
		}
	}
	p{
		font-family: "Uni Neue";
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		margin-top: 24px;

		&:nth-child(1){
			margin-top: 0;
		}
	}

	img{
		margin-top: 50px;
	}
}
footer{
	background-image: url(../img/footer.png);
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	padding-top: 98px;
	padding-bottom: 12px;

	.menu_bot{
		font-family: "Uni Neue";
		font-size: 18px;
		font-weight: 400;
		ul{
			li{
				margin-top: 18px;
				a{
					color: #fff;
				}
			}

			&:nth-child(2){
				margin-left: 65px;
			}
		}
	}
	.contact{
		padding-left: 62px;
		padding-top: 7px;
	}
	.phone{
		margin-top: 10px;
		a{
			padding-left: 36px;
			display: block;
			background-image: url(../img/telephone.png);
			background-repeat: no-repeat;
			background-position: left center;
			font-family: 'Gotham Pro Regular';
			font-size: 18px;
			line-height: 30px;
			color: #fff;
		}
	}
	.adr{
		margin-top: 30px;
		background-image: url(../img/maps.png);
		background-repeat: no-repeat;
		background-position: left top;
		color: #fff;
		font-family: "Uni Neue";
		font-size: 16px;
		line-height: 20px;
		padding-left: 35px;
	}

	.mail{
		margin-top: 12px;
		a{
			
			display: block;
			background-image: url(../img/mail.png);
			background-repeat: no-repeat;
			background-position: left center;
			font-family: "Uni Neue";
			font-size: 16px;
			color: #fff;
			padding:5px 0;
			padding-left: 36px;
		}
	}

	.copy{
		color: #fdc218;
		font-family: "Uni Neue";
		font-size: 14px;
		font-weight: 700;
		margin-top: 70px;
	}



}

.menu__icon {
    display: none;
    width: 20px;
    height: 25px;
    position: absolute;
    left: -220px;
    top: 25px;
    cursor: pointer;
}

.menu__icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #333333;
    border-radius: 0px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.menu__icon span:nth-child(1) {
    top: 0px;
}

.menu__icon span:nth-child(2), .menu__icon span:nth-child(3) {
    top: 5px;
}

.menu__icon span:nth-child(4) {
    top: 10px;
}
.menu__icon.menu_state_open{
	position: fixed;
}
.menu_state_open.menu__icon span{
	background: #fff;
}
.menu_state_open.menu__icon span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.menu_state_open.menu__icon span:nth-child(2) {
    transform: rotate(45deg);
}

.menu_state_open.menu__icon span:nth-child(3) {
    transform: rotate(-45deg);
}

.menu_state_open.menu__icon span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.menu_state_open.menu {
    display: block;  
    position: fixed;
    background: rgba(0,0,0,.5);
    z-index: 1;
    width: 100%;
    top: 0px;
    left: 0;

    ul{
    	display: block;
    	margin-top: 15px;
    	margin-bottom: 15px;

    	li{
    		border: none;
    		width: max-content;
    		margin: 0 auto;
    	}

    	a{
    		color: #fff;
    	}
    }
}



//стили страницы каталог -----------

.s10{
	background: #fff;
	padding-bottom: 70px;

	.catalog_items{
		margin-top: 50px;
		padding-top: 40px;
		padding-bottom: 50px;

-webkit-box-shadow: 0px 0px 11px 0px rgba(138,133,138,0.39);
-moz-box-shadow: 0px 0px 11px 0px rgba(138,133,138,0.39);
box-shadow: 0px 0px 11px 0px rgba(138,133,138,0.39);

		h3{
			color: #00121e;
			font-size: 30px;
			font-family: 'Gotham Pro Black';
			text-transform: uppercase;
			text-align: center;
		}
	}





.accordion {
  width:100%;
  max-width: 600px; 
  margin: 33px auto 0;
}
/* секции аккордеона */
.accordion .accordion_item {
  margin-bottom:1px;
  position:relative
}
/* заголовки панелей аккордеона */
.accordion .title_block {
	text-align: left;
	font-family: "Uni Neue";
	font-size: 18px;
	font-weight: 700;
	color: $black;
	cursor:pointer;
	
	padding:10px 25px 10px 70px;
	-webkit-transition:all .2s linear 0;
	-webkit-transition-delay:.2s 0;
	transition:all .2s linear 0;
	text-transform: initial;
}
.accordion .active_block .title_block{
	background: #fdc218;
}
/* переключатель панелей, положение вниз */
.accordion .title_block:before {
  content:'';
  height:8px;
  width:8px;
  display:block;
  border:2px solid black;
  border-right-width:0;
  border-top-width:0;
  -ms-transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  transform:rotate(-45deg);
  position:absolute;
  right:60px;
  top:18px
}
/* активный переключатель, положение вверх */
.accordion .active_block .title_block:before {
  border:2px solid black;
  border-left-width:0;
  border-bottom-width:0;
  top:18px
}
.accordion .title_block:hover {
  background: #ecaf01  
}

/* заголовок активного блока  */

/* блоки с содержанием */
.accordion .info {
  display:none;
  padding:18px 30px 30px 85px;
  overflow: hidden;

  ul{
	list-style-type: disc;
  	li{

  		font-family: "Uni Neue";
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		margin-top: 17px;

		&:nth-child(1){
			margin-top: 0;
		}

  		a{
  			color: $black;

  			strong{
  				font-weight: 700;
  			}
  			span{
  				color: #fdc218;
  			}
  		}
  	}
  }
}
/* параграф внутри блоков с содержанием */
.accordion .info_item {
  margin-bottom:10px
}
/* картинки внутри аккордеона */
.accordion .info img {
    height: auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
/* полноразмерная картинка */
.large-img {
    width: 100%;
    margin-bottom: 10px;
}
/* миниатюра слева */
.img-left {
    width: 25%;
    float:left;
    margin:5px 15px 5px 0;
}
/* миниатюра справа */
.img-right {
    width: 25%;
    float:right;
    margin:5px 0 5px 15px;
}
.items{
	margin-top: 35px;
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	padding-left: 23px;
}

.item{
			background: #fff;
			max-width: 218px;
			cursor: pointer;
			margin-top: 25px;
			margin-left: 25px;
			
			border: 1px solid #f5f5f5;
			
			&:hover{
				box-shadow: 0 0 10px rgba(253, 194, 24, 0.18);
				border: 1px solid #fdc218;

				.price{
					background: #fdc218;

					a{
						background-color: #00121e;
						background-image: url(../img/cart_h.png);
					}
				}
			}	
			
			.img{
				height: 230px;
				width: 100%;
				position: relative;
				display: block;
				overflow: hidden;

				img{
					display: block;
					margin: 0px auto 0;
				}

				.new{
					position: absolute;
					background: #28c621;
					color: #ffffff;
					font-family: 'Gotham Pro Regular';
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					top: 17px;
					padding: 8px;

					&:before{
						content: "";
						position: absolute;
						width: 0;
						display: block;
						height: 0;
						right: -10px;
						bottom: 0;
						border-bottom: 32px solid #28c621; 
						border-right:10px solid transparent;	
					}
				}

				.skidka{
					position: absolute;
					background: #ff0505;
					color: #ffffff;
					font-family: 'Gotham Pro Regular';
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					top: 17px;
					padding: 8px;

					&:before{
						content: "";
						position: absolute;
						width: 0;
						display: block;
						height: 0;
						right: -10px;
						bottom: 0;
						border-bottom: 32px solid #ff0505; 
						border-right:10px solid transparent;	
					}
				}



			}
			

			h3{
				font-size: 18px;
				font-family: 'Uni Neue';
				font-weight: 700;
				margin-left: 27px;
				margin-top: 20px;
				padding-right: 30px;
			}
			p{
				font-family: 'Uni Neue';
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				margin-left: 27px;
				margin-top: 12px;
				padding-right: 20px;
			}

			.price{
				font-size: 24px;
				font-family: 'Uni Neue';
				font-weight: 700;
				margin-top: 23px;
				padding-top: 10px;
				padding-left: 27px;
				padding-bottom: 10px;
				background-color: #fffbee;

				display: flex;
				align-items: center;
				justify-content: space-between;

				a{
					background-image: url(../img/cart.png);
					background-repeat: no-repeat;
					background-position: center;
					width: 39px;
					height: 39px;
					margin-right: 13px;
					margin-top:5px;
					display: block;
					background-color: #fdc218;
					border-radius: 5px;
					padding: 10px;
				}
			}

		}


}



.s4#s11{
	background-image: url(../img/b11.png);
	    padding-top: 96px;
    padding-bottom: 80px;

    h3{
    	color: $black;
    }
}


.s12{
	padding-top: 33px;
	background: #fff;

	padding-bottom: 90px;


	.teh_opisanie{
		background: #00121e;
		width: max-content;
		margin-top: 40px;

		a{
			margin-left: 75px;
			color: #fdc218;
			font-family: 'Uni Neue';
			font-weight: 400;
			position: relative;
			font-size: 18px;
			padding: 25px 19px;
			display: block;
			position: relative;


			&:before{
				display: block;
				content: "";
				padding: 34px;
				background-position: center;
				top: 0;
				left: -75px;
				background-image: url(../img/pdf.png);
				background-color: #fdc218;
				background-repeat: no-repeat;
				position: absolute;
			}

			
		}

		&:hover{
				background: #053556;
		}
	}
	.thumbelina-but.vert.top, .thumbelina-but.vert.bottom{
		border: none;
		box-shadow: none;
		border-radius: 0;
	}
	.thumbelina-but.vert {
	    left: -1px;
	    height: 20px;
	    line-height: 27px;
	    width: 66px;
	    margin-left: 5px;
	}
	.align-center{
		margin-top: 35px;
		align-items: center;
		justify-content: space-between;

		.img{
			width: 100%;

			img{
				display: block;
				margin: 0 auto;
			}
		}
	}

	.pagination{
		a{
			font-family: 'Uni Neue';
			font-weight: 400;
			font-size: 14px;
			color: #bbbbbb;
			margin-left: 10px;
			&:nth-child(1){
				margin-left: 0;
			}
			&:last-child{
				font-weight: 700;
				color: $black;
			}
		}
	}
	h2.cart{
		font-family: 'Uni Neue';
		font-weight: 400;
		font-size: 36px;
		color: $black;
		margin-top: 34px;
	}

#slider {
    position:relative;
    margin-top:20px;
    width:93px;
    height:340px;
    margin-bottom:20px;
    a{
    	cursor: pointer;
    }
    img{
    	width: 100%;

    }
}

.description{
	margin-left: 165px;
	margin-top: 35px;
	.art{
		font-size: 12px;
		font-family: 'Uni Neue';
		font-weight: 400;
		color: #7f7f7f;
		text-align: center;
		margin-top: 10px;


	}
	.litr{
		text-align: center;
		background: #fffbee;
		border: 1px solid #fffbee;
		font-size: 24px;
		font-family: 'Uni Neue';
		font-weight: 700;
		color: $black;
		padding: 10px 0;
	}
	.item{
		margin-left: 33px;
		cursor: pointer;
		&:nth-child(1){
			margin-left: 0;
		}
	}
	.item.selected{
		.litr{
			border: 1px solid #fdc218;
		}
	}

	.one_click_order{
		background-color: #fdc218;	
		width: max-content;
		margin-top: 47px;
		border-radius: 4px;
		transition:.4s all;

		&:hover{
			background-color: #fbbb03;
		}
		a{
			padding: 14px 63px 14px 23px;
			display: block;
			color: $black;
			font-size: 18px;
			font-family: 'Uni Neue';
			font-weight: 700;
			background-image: url(../img/shopping-cart.png);
			background-repeat: no-repeat;
			background-position: 220px center;

		}
	}

	p{
		font-size: 14px;
		font-family: 'Uni Neue';
		font-weight: 400;
		line-height: 20px;
		color: $black;
		margin-top: 45px;
		max-width: 520px;
	}

}

	#dop_info{
		margin-top: 35px;
		color: #fdc218;
		font-size: 24px;
		font-family: 'Uni Neue';
		font-weight: 400;
		position: relative;

		&:before{
			position: absolute;
			content: "";
			width: 100%;
			height: 1px;
			background: #fdc218;
			bottom: 4px;
			left: 330px;
		}
	}

	.overflov-hidden{
		overflow: hidden;
	}

	.dop_info{
		margin-top: 33px;

		a{
			display: block;
			height: 50px;
			color: #00121e;
			background-image: url(../img/pdf.png);
			background-repeat: no-repeat;
			background-position:left center;
			display: flex;
			align-items: center;
			font-size: 18px;
			padding-left: 55px;
			margin-top: 17px;

			&:nth-child(1){
				margin-top: 0;
			}
		}
	}

}







@import 'media';